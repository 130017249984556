import React from "react";
import {styled} from "@mui/system";
import {Button, Card} from "@mui/material";

export const ImageHeader = styled(Card)({
    width: '100%',
    height: '30vh',
    selfAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
    boxShadow: 'none',
    color: 'white',
    flexDirection: 'column',
    textAlign: 'center',
    backgroundImage: 'url("/blogbg.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    '@media (max-width:768px)': {
        marginTop: '1.5rem',
    }
});

export const BlogPageHeader = styled(Card)({
   width: '100%',
   height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    boxShadow: 'none',
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: 'white',
    '@media (max-width:768px)': {
        flexDirection: 'column',
        marginTop: '1rem'
    }
});

// each container can contain only three cards
export const BlogsContainer = styled(Card)({
    margin: '3rem 0rem',
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    background: 'transparent',
    boxShadow: 'none',
    color: 'white',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
    }
});

// contains BlogCard with an image stacked on top
export const BlogBox = styled(Card)({
    width: '28vw',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    background: 'transparent',
    boxShadow: 'none',
    color: 'white',
    textAlign: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
        width: '85vw',
        marginBottom: '1.5rem'
    }
});

export const HomeBlogCard = styled(Card)({
    width: '25vw',
    height: '30rem',
    display: 'flex',
    flexDirection: 'column',
    background: 'transparent',
    boxShadow: 'none',
    color: 'white',
    textAlign: 'center',
    borderWidth: '1px',
    borderColor: 'white',
    borderStyle: 'solid',
    marginTop: '1rem',
    justifyContent: 'space-evenly',
    '@media (max-width: 768px)': {
        width: '80vw',
        marginBottom: '1.5rem'
    },
    '& p': {
        paddingLeft: '1rem',
        paddingRight: '1rem',
        lineHeight: '1.5rem',
    }
});

export const PaginationButton = styled(Button)({
    display: 'inline-block',
    outline: 0,
    cursor: 'pointer',
    border: '2px solid #000',
    borderRadius: '3px',
    color: '#000',
    background: '#fff',
    fontSize: '0.8rem',
    padding: '12px 20px',
    textAlign: 'center',
    '&:hover': {
        background: 'rgb(251, 193, 245)',
    }
});

export const BlogTagsContainer = styled(Card)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    boxShadow: 'none',
    color: 'white',
    textAlign: 'center',
});

export const BlogTag = styled(Button)({
    width: 'auto',
    height: 'auto',
    display: 'in-line-block',
    marginRight: '0.5rem',
    cursor: 'pointer',
    border: '3px solid black',
    borderRadius: '10px',
    color: '#000',
    background: '#d0f1c1',
    fontSize: '0.8rem',
    textAlign: 'center',
    transitionDuration: '.15s',
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
    '&:hover': {
        background: 'rgb(251, 193, 245)',
    }
});

export const BlogCard = styled(Card)({
    marginTop: '2rem',
    lineHeight: '2rem',
    width: '45%',
    height: 'auto',
    selfAlign: 'center',
    display: 'flex',
    background: 'transparent',
    boxShadow: 'none',
    color: 'white',
    flexDirection: 'column',
    textAlign: 'left',
    '@media (max-width: 768px)': {
        width: '80vw',
    }
});

