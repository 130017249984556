import React from "react";
import RomanizedMMImg from "../../images/romanizedmmsong.png";
import {CustomCard} from "../CustomCard";
import {List} from "@mui/material";
import ContactBar from "../../components/ContactBar/ContactBar";

function RomanizedMM() {
    return (
        <div className="project-page">
            <h1>RomanizedMM</h1>
            <img src={RomanizedMMImg} alt="RomanizedMM"></img>
            <h3><a href="https://www.youtube.com/watch?v=9P4RoPcO_RM&t">Demo</a> + More on <a href="https://github.com/ynwin1/RomanizedMM">GitHub</a></h3>
            <h3><u>Tech stack</u>: React, Node.js, MongoDB, Express.js</h3>
            <CustomCard>
                <p>
                    I have had this idea of creating a web app to provide Romanized lyrics for Burmese songs long enough. Back then,
                    I was not very proficient with front-end, so I took the time to learn React to the point where I felt comfortable
                    to tackle this as my first React-based project. Having used other components of MERN stack before, I decided to go with it.
                    This is my first shot at a full-stack project, and I think I am making a good progress on it. I have a few users
                    that visit daily from different countries, and I am very happy to see that.
                </p>
                <h3><u>Features</u></h3>
                <List>
                    <li>Romanized Lyrics, Burmese and Meanings</li>
                    <li>Request songs through APIs linked to Discord</li>
                    <li>Light/Dark Mode</li>
                    <li>Intelligent Search Bar</li>
                </List>
                <p>
                    In early April, I made a huge update to the app. My previous version was a simple one-page app where songs are fetched from
                    the database as user looks for a song. There was nothing wrong with this approach, except that Google cannot index the
                    site properly with this one-page structure. Since content appears only after the user selects a song, there was no way
                    for Google to know what the site is about. Instead, I decided to create pages for each song, where the songs are locally stored in the project,
                    and pages are static. This way, Google can index the site properly, and users can share the link to the song they like. Only
                    after this, I got more users visiting the site. Other features remain the same.
                </p>

                <h3><u>Experience & Challenges</u></h3>
                <p>
                    Front-end was rather a challenge than the back-end. It was a great way for me to get back on track in terms of
                    HTML and CSS, combined with JS. One challenging part of React was applying hooks and states properly. Although I had laid out
                    how to structure the app, there were times where I had to go back and think "Will this work later when I implement this & that?".
                </p>
                <p>
                    I wanted to maintain the states of the song searched in the search bar, and some
                    of these information had to be passed around to different components. Now, I had to think, what props am I going
                    to pass down? What states am I going to maintain in the parent component? How do I pass these states down to the child components?
                    Are they going to get passed further down? These were some of the questions I had to ask myself. I had to figure out what I wanted
                    to do with this data, and passed accordingly. With the answers to these questions, I was able to structure the app in a way that
                    successfully maintains the states and props.
                </p>
                <p>
                    In terms of backend, there wasn't much of a challenge. It mainly consisted of 2 APIs that searched the song and for the user to submit
                    a song request which packages the submitted data and sends it to my Discord channel. I had to make sure that the data was sent properly,
                    by writing some tests around these APIs. For the songs, whenever I had to add a new song, I run a script to ensure it contains all the
                    details needed for the song to be displayed properly. This way, I can maintain the highest integrity of the data. However, with new changes,
                    there is no need to execute the API for fetching songs, as they are now stored locally in the project.
                </p>
            </CustomCard>
            <ContactBar/>
        </div>
    )
}

export default RomanizedMM;