import React from "react";
import { IconButton, Link } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';

const contactBarStyling = {
    contactBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 'auto'
    },
    iconButton: {
        color: 'white',
        margin: '1rem 3rem',
    },
    icon: {
        fontSize: '2rem',
    }
}

function ContactBar() {
    return (
        <div style={contactBarStyling.contactBar}>
            <Link href="https://www.linkedin.com/in/ynwin1" target="_blank">
                <IconButton style={contactBarStyling.iconButton}>
                    <LinkedInIcon style={contactBarStyling.icon} />
                </IconButton>
            </Link>
            <Link href="https://github.com/ynwin1" target="_blank">
                <IconButton style={contactBarStyling.iconButton}>
                    <GitHubIcon style={contactBarStyling.icon} />
                </IconButton>
            </Link>
            <Link href="https://www.instagram.com/ynwin_1" target="_blank">
                <IconButton style={contactBarStyling.iconButton}>
                    <InstagramIcon style={contactBarStyling.icon} />
                </IconButton>
            </Link>
        </div>
    )
}

export default ContactBar;

