import React from "react";
import mediquestLogo from "../../images/mediquestlogo.jpg";
import {CustomCard} from "../CustomCard";
import ContactBar from "../../components/ContactBar/ContactBar";

function MediQuest() {
    return (
        <div className="project-page">
            <h1>MediQuest</h1>
            <img src={mediquestLogo} alt="MediQuest"></img>
            <h3><a href="https://www.youtube.com/watch?v=whklIhXrc0U">Demo</a> + <a href="https://github.com/Payam-Fz/MediQuest">GitHub</a></h3>
            <h3><u>Tech stack</u>: C#, Unity</h3>
            <CustomCard>
                <p>
                    I was placed in a team called "CodeBlue" at the beginning of the semester in 2022 to gain some insights into game development.
                    The game was halfway through the production, and they were working on a medical game where the user gets to play as an resident surgeon whose task is to diagnose patients.
                    I worked as a developer to mainly take care of the coding related to the new features, as well as upgrading the existing code.
                    We won the "Game of the Year" award at the UBC Game Dev Club Year End Show which was voted by several judges from the industry.
                </p>
                <p>Here are a few tasks that I worked on during the time.</p>
                <h3><u>Redesigning the dialogue system</u></h3>
                <p>
                    When I first worked on the dialogue system, it was just a simple 1D array storing all the dialogues.
                    With the introduction of the save/load mechanism and more storylines coming up, the leader and I decided
                    to come up with a hierarchical dialogue system which allows dialogues to act as checkpoints, thus users
                    can pick up from where they left off. I wrote a small algorithm that allows the programmers to input the
                    storyline and let the program sort out how they can be stored as dialogues in the hiearachy. Storing in a
                    hiearachy meant that when we switch scenes, it allows dialogue to be temporarily saved and thus, can
                    continue the game from the last scene. Also, when a player quits and resumes again, the hiearachy can
                    retrieve the last checkpoint.
                </p>
                <h3><u>Patient Documentation</u></h3>
                <p>
                    I worked on two aspects of patient documentation; a patient chart and a test chart. A patient chart involved
                    different sectors which are related to other parts of the game. For example, a function of patient chart
                    is to order tests for patients which is done via a test chart. Actions from test chart have to be
                    stored correctly so the doctor can order the correct tests. This whole process required understanding
                    of the relationship between different components of the game and made me to read all the code related
                    to the charts. The same thing applied when I had to extract data from the patient to fill up the charts.
                </p>
            </CustomCard>
            <ContactBar/>
        </div>
    )
}

export default MediQuest;