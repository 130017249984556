import React, {useEffect, useState} from "react";
import {ProjectAbout, ProjectBox, ProjectCard, WebButton} from "./ProjectCard";
import {List} from "@mui/material";
import ReactPlayer from "react-player";
import { useNavigate } from 'react-router-dom';
import ContactBar from "../../components/ContactBar/ContactBar";

function Projects() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <div className="projects">
            <h1>Projects & Hackathons</h1>
            <ProjectCard first={true}>
                <ProjectBox>
                    <div className="project-image">
                        <a href="https://www.romanizedmm.com">
                        <img src="./romanizedmm.png" alt="RomanizedMM">
                        </img>
                        </a>
                    </div>
                    <ProjectAbout>
                        <h2><a href="https://www.romanizedmm.com"><u>RomanizedMM</u> 🔗</a></h2>
                        <List>
                            <li><b>Project Type</b>: Personal</li>
                            <li><b>Tech stack</b>: React, Node.js, MongoDB, Express.js</li>
                            <li><b>Duration</b>: Dec 2023 - current</li>
                            <li><b>Goal</b>: To provide romanized lyrics of Burmese music for international audiences</li>
                            <li><b>Features</b>: Romanized lyrics, Integrated Media Players, Song Requests</li>
                        </List>
                        <p>RomanizedMM is a web application that provides romanized lyrics of Myanmar songs to music lovers
                            out there who wants to sing our songs along.</p>
                        <WebButton onClick={() => {
                            window.scrollTo(0, 0);
                            navigate('/projects/romanizedmm')
                        }}>
                            View More
                        </WebButton>
                    </ProjectAbout>
                </ProjectBox>
            </ProjectCard>
            <ProjectCard first={true}>
                <ProjectBox>
                    <ProjectAbout>
                        <h2><a href="https://github.com/Vihangip/EchoEmpathy"><u>Echo Empathy</u> 🔗</a></h2>
                        <List>
                            <li><b>Project Type</b>: Hackathon (NWPlus 2024)</li>
                            <li><b>Tech stack</b>: React, Python, OpenAI, PRAW</li>
                            <li><b>Duration</b>: Jan 2024</li>
                            <li><b>Goal</b>: To connect people with similar challenges through Reddit posts</li>
                            <li><b>Features</b>: Reddit posts recommendations, OpenAI </li>
                        </List>
                        <p>Echo Empathy is a web application is a web application designed to connect individuals experiencing mental
                            health challenges with others in similar situations, using shared experiences posted on Reddit.
                        </p>
                        <p>In this hackathon, I took on a new challenge by taking care of the frontend. As a long-time backender,
                            this was the best opportunity I could get to apply my React skills that I had learnt recently.</p>
                    </ProjectAbout>
                    <div className="project-image">
                        <a href="https://github.com/Vihangip/EchoEmpathy">
                            <img src="./echoempathy.png" alt="EchoEmpathy">
                            </img>
                        </a>
                    </div>
                </ProjectBox>
            </ProjectCard>
            <ProjectCard first={true}>
                <ProjectBox>
                    <div className="project-image">
                        <a href="https://github.com/Payam-Fz/MediQuest">
                            <img src="./mediquest.jpg" alt="MediQuest">
                            </img>
                        </a>
                    </div>
                    <ProjectAbout>
                        <h2><a href="https://github.com/Payam-Fz/MediQuest"><u>MediQuest</u> 🔗</a></h2>
                        <List>
                            <li><b>Project Type</b>: UBC Game Dev Club</li>
                            <li><b>Tech stack</b>: C#, Unity</li>
                            <li><b>Duration</b>: Sep 2021 - Apr 2022</li>
                            <li><b>Goal</b>: To explore the life of a surgeon through gaming</li>
                            <li><b>Features</b>: Storylines, Time limited tasks, Consulting knowledge book</li>
                        </List>
                        <p>
                            MediQuest is a 2D game built using C# and Unity that provides an insight into the life of
                            a surgeon. The player learns about medicine through performing tasks.
                        </p>
                        <WebButton onClick={() => {
                            window.scrollTo(0, 0);
                            navigate('/projects/mediquest')
                        }}>
                            View More
                        </WebButton>
                    </ProjectAbout>
                </ProjectBox>
            </ProjectCard>
            <ProjectCard first={true}>
                <ProjectBox>
                    <ProjectAbout>
                        <h2><a href="https://github.com/ynwin1/Fantasy-Assist"><u>FPL Assist</u> 🔗</a></h2>
                        <List>
                            <li><b>Project Type</b>: Course Project (CPSC 210)</li>
                            <li><b>Tech stack</b>: Java, Swing</li>
                            <li><b>Duration</b>: Jul 2021 - Aug 2021</li>
                            <li><b>Goal</b>: To assist FPL players with data to ease decision making</li>
                            <li><b>Features</b>: FPL team, Data dashboard</li>
                        </List>
                        <p>
                            FPL Assist is a Java application built to provide FPL players some crucial details needed
                            to finalize their teams in real world FPL. The application provides data on players and teams
                            involved in the Premier League.
                        </p>
                        <WebButton onClick={() => {
                            window.scrollTo(0, 0);
                            navigate('/projects/fplassist')
                        }}>
                            View More
                        </WebButton>
                    </ProjectAbout>
                    <div className="project-image">
                        <a href="https://github.com/ynwin1/Fantasy-Assist">
                            <img src="./HomePanel.jpg" alt="FPL Assist"></img>
                        </a>
                    </div>
                </ProjectBox>
            </ProjectCard>
            <ProjectCard first={true}>
                <ProjectBox>
                    <div className='project-video'>
                        <ReactPlayer
                            url="https://www.youtube.com/watch?v=XnQzYU2jc3Y"
                            width= {windowWidth <= 768 ? '70vw' : '30vw'}
                            height={windowWidth <= 768 ? '40vh' : '35vh'}
                            controls={true}
                            marginTop='1rem'>
                        </ReactPlayer>
                    </div>
                    <ProjectAbout>
                        <h2><u>UBC Explorer</u></h2>
                        <List>
                            <li><b>Project Type</b>: Course Project (CPSC 310)</li>
                            <li><b>Tech stack</b>: React, TypeScript, Mocha, Chai</li>
                            <li><b>Duration</b>: Sep 2023 - Dec 2023</li>
                            <li><b>Goal</b>: To provide historical data & info about the courses and campus at UBC </li>
                            <li><b>Features</b>: Query datasets, Courses and Rooms Info Dashboard</li>
                        </List>
                        <p>
                            UBC Explorer is a web-app built using React and TypeScript as part of CPSC 310 project. The features
                            consist of querying data as per user needs, and displaying appropriate information. It also includes an admin
                            panel to CRUD data through our APIs. The most challenging aspect of this was building the query tree and debugging
                            it. We wrote unit and e2e tests using Mocha and Chai.
                        </p>
                        <p>
                            ** Unfortunately, this project cannot be made public due to the course policy. I have provided
                            a demo video instead.
                        </p>
                    </ProjectAbout>
                </ProjectBox>
            </ProjectCard>
            <ProjectCard first={true}>
                <ProjectBox>
                    <ProjectAbout>
                        <h2><a href="https://github.com/ynwin1/UniTalkCA"><u>UniTalk CA</u> 🔗</a></h2>
                        <List>
                            <li><b>Project Type</b>: Hackathon (NWPlus 2023)</li>
                            <li><b>Tech stack</b>: React, Node.js, MongoDB, Express.js</li>
                            <li><b>Duration</b>: Jan 2023</li>
                            <li><b>Goal</b>: To provide a platform for students in Canada to discuss educational matters</li>
                            <li><b>Features</b>: User Authentication, Discussion Panels, Info Boards</li>
                        </List>
                        <p>
                            This was my very first shot at the MERN stack where I worked on the backend of the application. It was
                            more of a learning process, as I learnt new things while shaping this project. This was also my first
                            time writing APIs from scratch, and connecting them to the frontend.
                        </p>
                    </ProjectAbout>
                    <div className="project-image">
                        <a href="https://github.com/ynwin1/UniTalkCA">
                            <img src="./unitalkca.png" alt="UniTalk CA"></img>
                        </a>
                    </div>
                </ProjectBox>
            </ProjectCard>
            <ContactBar/>
        </div>
    )
}

export default Projects;