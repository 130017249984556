import React from "react";
import {styled} from "@mui/system";
import {Button, Card} from "@mui/material";
import {WebButton} from "../Projects/ProjectCard";

export const QuizCard = styled(Card) ({
    width: '100%',
    height: 'auto',
    selfAlign: 'center',
    display: 'flex',
    background: 'transparent',
    boxShadow: 'none',
    color: 'white',
    flexDirection: 'column'
});

export const OptionButtonStyled = styled(Button)({
    width: '100%',
    height: 'auto',
    display: 'flex',
    background: 'transparent',
    boxShadow: 'none',
    borderStyle: 'solid',
    borderColor: 'white',
    color: 'white',
    borderWidth: '1px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
    // change color on hover
    '&:hover': {
        color: 'black',
        background: 'white',
    }
});

export const Quiz = ({quiz, onAnswerClick}) => {
    return (
        <QuizCard>
            <h3>{quiz.question}</h3>
            {quiz.answers.map((answer, index) => (
                <OptionButtonStyled key={index} onClick={() => onAnswerClick(answer)}>
                    {answer}
                </OptionButtonStyled>
            ))}
        </QuizCard>
    )
}
export const QuizButton = ({ onClick, message }) => {
    return (
        <WebButton onClick={onClick}>
            {message}
        </WebButton>
    )
};