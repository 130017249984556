import React from "react";
import {CustomCard} from "../CustomCard";
import {IconButton, Link, List, Paper} from "@mui/material";
import ContactBar from "../../components/ContactBar/ContactBar";
import InstagramIcon from "@mui/icons-material/Instagram";

function University() {
    return (
        <div className="university">
            <h1>University</h1>
            <img src="./university.jpg" alt="UBC"></img>
            <CustomCard>
                <h3><u>Undergraduate</u></h3>
                <p>
                    I am in my last year of undergraduate at the University of British Columbia studying Computer Science.
                    I started university back in 2020 during the pandemic.
                    It was online during the first year, and it was pretty rough. On top of this was the pressure to
                    get into Computer Science which made it even more challenging. Nonetheless, I managed to get through it.
                </p>
                <p>
                    Second year was when I stepped foot on campus for the first time. It was a whole new experience for me.
                    New people, new environment, new everything. It was a great year.
                </p>
                <p>
                    I recently finished my third year at UBC. As much as I enjoyed the first term, the second term was the toughest one throughout
                    my university life. I underestimated the workload, and the difficulty of CPSC320, but it was very interesting to learn about
                    Dynamic Programming. That was my favorite part of the course.
                </p>
                <h3><u>CS courses I have completed </u></h3>
                <Paper style={{maxHeight: 150, overflow: 'auto',
                    background: 'transparent', color: 'white', marginBottom: '2rem', boxShadow: 'none'}}>
                    <List>
                        <li>CPSC 110 - Computation, Programs, and Programming</li>
                        <li>CPSC 121 - Models of Computation</li>
                        <li>CPSC 210 - Software Construction</li>
                        <li>CPSC 213 - Introduction to Computer Systems</li>
                        <li>CPSC 221 - Basic Algorithms and Data Structures</li>
                        <li>CPSC 304 - Introduction to Relational Database</li>
                        <li>CPSC 310 - Introduction to Software Engineering</li>
                        <li>CPSC 313 - Computer Hardware and Operating Systems</li>
                        <li>CPSC 317 - Introduction to Computer Networking</li>
                        <li>CPSC 320 - Intermediate Algorithm Design and Analysis</li>
                        <li>CPSC 322 - Introduction to AI</li>
                        <li>CPSC 330 - Applied Machine Learning</li>
                    </List>
                </Paper>
                <h3><u>Clubs & Activities</u></h3>
                <h4>UBC Game Development Club - 2021/22</h4>
                <p>
                    I joined the UBC Game Development Club in my second year. I've always wanted to know more about game
                    development. There were many teams working on different games and I was randomly placed into a
                    team named "CodeBlue". There, I worked with Unity and C# to develop a game called "MediQuest". I worked
                    on redesigning and implementing some in-game logics, as well as introducing some new features.
                    We won the "Best Overall Game" award at the end of the year. It was a great experience.
                </p>
                <h4>UBC Myanmar Student Club - 2023/24 - current</h4>
                <Link href="https://www.instagram.com/ubcmmclub" target="_blank">
                    <IconButton>
                        <InstagramIcon style={{width: '2rem', height: 'auto', color: 'white'}}/>
                    </IconButton>
                </Link>
                <p>
                    A few Burmese people studying at UBC started this club at the beginning of 2023 school year. We started
                    functioning as a club in 2nd term where I worked as a Treasurer. It was a great way to get out of my
                    comfort zone and work on something that I am not at all familiar with. I learnt money management
                    for clubs with UBC AMS, from depositing, withdrawing, to budgeting. I also got to know a lot of Burmese
                    who I have never met before. I will be working as a President starting next academic year. I'm really looking
                    forward to us earning a good reputation in the UBC community.
                </p>
            </CustomCard>
            <ContactBar/>
        </div>
    )
}

export default University;