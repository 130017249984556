import React from "react";
import {CustomCard} from "../CustomCard";
import ContactBar from "../../components/ContactBar/ContactBar";

function About() {
    return (
        <div className="about">
            <img className="my-pic" src="./ynw.jpg" alt="Yan Naing Win"></img>
            <CustomCard>
                <h1>About</h1>
                <p>Hi! I am Yan Naing Win. Most call me Yan, some call me Yan Naing (sounds like 9), either's
                fine. I am now into my last year of undergraduate at the University of British Columbia in
                Vancouver, Canada studying Computer Science.</p>
                <p>I am originally from a city named Yangon a.k.a Rangoon in Myanmar(Burma). Have you heard
                of a dish called "Crab Rangoon"? Well, it has nothing to do with us 😆. Myanmar's a beautiful hidden gem
                    in Southeast Asia, maybe you should consider visiting us when things have settled down in my country 😉.
                </p>
                <figure>
                    <img src="./yangon.jpg" alt="Yangon"></img>
                    <figcaption>
                        Downtown Yangon - Photo by Alexander Schimmeck
                    </figcaption>
                </figure>
                <p>
                    I like to think of myself as a developer. Most of the things I have built come from my own experiences, and that always makes me believe
                    in my own projects. I am a believer of "build it and they will come" mentality. Most of my concepts lie around my country,
                    because I feel there are many day-to-day problems that can be solved with software applications. The more I dive into it, I more
                    I realize about the reality which sparks my ideas. I'm always looking for opportunities that people will find useful.
                </p>
                <p>
                    I am a big fan of football (soccer). I played as a keeper for my school team since I was in middle school. I am a die-hard supporter of my national football team.
                    We're ranked 163th in the world, but my love for the team is beyond the rankings. I really really want to see us in the World Cup for once, but it's a long way to go.
                </p>
                <figure>
                    <img src="./footy.JPG" alt="Football Match"></img>
                    <figcaption>
                        After our 9-0 win on an away day in 2015
                    </figcaption>
                </figure>
            </CustomCard>
            <ContactBar/>
        </div>
    )
}

export default About;