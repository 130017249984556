import React, {useEffect, useState} from "react";
import {
    BlogBox,
    HomeBlogCard,
    BlogsContainer,
    BlogTag,
    BlogTagsContainer,
    ImageHeader,
    PaginationButton
} from "./BlogComponents";
import {WebButton} from "../Projects/ProjectCard";
import {useNavigate} from "react-router-dom";
import {BlogHost} from "./BlogHost";

function BlogHome() {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [blogs, setBlogs] = useState([]);
    const pageSize = 3;
    const pageCount = Math.ceil(blogs.length / pageSize);
    const navigate = useNavigate();

    function handleNextPage() {
        setCurrentPage(currentPage + 1);
    }

    function handlePrevPage() {
        setCurrentPage(currentPage - 1);
    }

    function initBlogs() {
        const allBlogs = BlogHost();
        setBlogs(allBlogs);
    }

    useEffect(() => {
        initBlogs();
    }, []);

    return (
        <div className="blog-home">
            <ImageHeader>
                <h1>Blog</h1>
                <p>I like to write about my own experiences</p>
            </ImageHeader>
            <BlogsContainer>
                {blogs.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((blog) => (
                    <BlogBox key={blog.id}>
                        <img className="blog-img" src={blog.image} alt="Coop"/>
                        <HomeBlogCard>
                            <h2>{blog.title}</h2>
                            <BlogTagsContainer>
                                {blog.tags.map((tag) => (
                                    <BlogTag>{tag}</BlogTag>
                                ))}
                            </BlogTagsContainer>
                            <p>{blog.date}</p>
                            <p><b>{blog.timeToRead}</b></p>
                            <p>{blog.content.length > 240 ? blog.content.substring(0, 240) + "....." : blog.content}</p>
                            <WebButton onClick={() => {
                                window.scrollTo(0, 0);
                                navigate(`${blog.id}`);
                            }}>Read more</WebButton>
                        </HomeBlogCard>
                    </BlogBox>
                ))}
            </BlogsContainer>
            <div className="pagination">
                <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>Prev</PaginationButton>
                <PaginationButton onClick={handleNextPage} disabled={currentPage === pageCount}>Next</PaginationButton>
            </div>
        </div>
    )
}

export default BlogHome;