import {Button, Card} from "@mui/material";
import {styled} from "@mui/system";

export const ProjectCard =  styled(Card)(({first}) =>({
    width: '90%',
    height: 'auto',
    selfAlign: 'center',
    display: 'flex',
    background: 'transparent',
    boxShadow: 'none',
    color: 'white',
    marginBottom: '1.5rem',
    borderStyle: first ? 'solid':'none',
    borderColor: first ? 'aqua':'none',
}));

export const ProjectBox = styled('div')({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    '@media (max-width:768px)': {
        flexDirection: "column",
        marginTop: '0.5rem',
        marginBottom: '0.5rem'
    }
});

export const ProjectAbout = styled('div')({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "1rem",
    color: "white",
    width: "40vw",
    '@media (max-width:768px)': {
        width: "80vw",
        fontSize: "1rem",
    }
});

export const WebButton = styled(Button)({
    alignSelf: 'center',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    backgroundPosition: '0 90%',
    backgroundRepeat: 'repeat no-repeat',
    backgroundSize: '4px 3px',
    borderRadius: '15px 225px 255px 15px 15px 255px 225px 15px',
    borderStyle: 'solid',
    borderWidth: '2px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 15px 28px 25px -18px',
    boxSizing: 'border-box',
    color: '#41403e',
    cursor: 'pointer',
    display: 'inline-block',
    fontFamily: 'Neucha, sans-serif',
    fontSize: '1rem',
    lineHeight: '23px',
    outline: 'none',
    padding: '0.75rem',
    textDecoration: 'none',
    transition: 'all 235ms ease-in-out',
    borderBlockStyle: 'solid',
    userSelect: 'none',
    webkitUserSelect: 'none',
    touchAction: 'manipulation',
    marginTop: '1rem',
    marginBottom: '1rem',

    '&:hover': {
        boxShadow: 'rgba(255, 255, 255, 255) 2px 8px 8px -5px',
        transform: 'translate3d(0, 2px, 0)',
        border: '2px solid #ffffff',
        color: '#ffffff'
    },

    '&:focus': {
        boxShadow: 'rgba(0, 0, 0, 0.3) 2px 8px 4px -6px'
    }
});