import React from "react";
import {CustomCard} from "../CustomCard";
import ContactBar from "../../components/ContactBar/ContactBar";

function Home() {
    return (
        <div className="home">
            <img src="./WorkAtVPL.jpg" alt="Working at the library"></img>
            <CustomCard>
                <h2>Hi! I am Yan Naing!</h2>
                <p>April 27th 2024</p>
                <p>The day after my last exam of third year. I packed my
                    bag and headed to Vancouver Public Library in downtown. There, I went to the 6th floor, sat at the
                    table in the corner, and started building this website while listening to Laufey's music. Jazz is ❤️.
                </p>
                <ContactBar/>
            </CustomCard>
        </div>
    )
}

export default Home;