import React, {useState, useEffect} from "react";
import questions from "./quiz.json";
import {Quiz, QuizButton} from "./TriviaComps";
import {CustomCard} from "../CustomCard";

function Trivia() {
    const [quiz, setQuiz] = useState([]);
    const [score, setScore] = useState(0);
    const [quizStarted, setQuizStarted] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState(null);

    // use UseEffect to select 10 random questions from the quiz.json file
    useEffect(() => {
        const randomQuestions = [];
        while (randomQuestions.length < 10) {
            const randomIndex = Math.floor(Math.random() * questions.length);
            const randomQuestion = questions[randomIndex];
            // avoid duplicates
            if (!randomQuestions.includes(randomQuestion)) {
                randomQuestions.push(randomQuestion);
            }
        }
        setQuiz(randomQuestions);
    }, [quizStarted]);

    function handleAnswer(answer) {
        setSelectedAnswer(answer);
        setTimeout(() => {
            const isCorrect = answer === quiz[currentQuestion].correctAnswer;
            if (isCorrect) {
                setScore(prevScore => prevScore + 1);
            }
            setCurrentQuestion(currentQuestion + 1);
            setSelectedAnswer(null);
        }, 4000);
    }

    function resetQuiz() {
        setCurrentQuestion(0);
        setScore(0);
        setQuizStarted(false);
    }

    return (
        <div className="trivia">
            <h1>Trivia</h1>
            {!quizStarted ? (
                <CustomCard>
                    <h2>How well do you know Yan Naing?</h2>
                    <p>There are 10 questions in total. Let's see what you've got!</p>
                    <p>It's just for fun. I am not collecting any data nor I can see who did what.</p>
                    <QuizButton onClick={() => setQuizStarted(true)} message="Start Now"/>
                </CustomCard>
            ) : // display right or wrong when answer is selected
                selectedAnswer !== null ? (
                <CustomCard>
                    <h1>{selectedAnswer === quiz[currentQuestion].correctAnswer ? "Correct! ✅" : "Not quite right! ❌"}</h1>
                    <h3>{selectedAnswer !== quiz[currentQuestion].correctAnswer ? `Correct answer - ${quiz[currentQuestion].correctAnswer}`:""}</h3>
                    {quiz[currentQuestion].image &&
                        <img src={quiz[currentQuestion].image} alt="quiz-img"
                             style={{width: '20rem', height: 'auto', alignSelf: "center",
                        borderWidth: '3px', borderColor: 'white', borderStyle: 'solid', borderRadius: '1rem'}}
                        />
                    }
                    <h3>{quiz[currentQuestion].comment}</h3>
                </CustomCard>
            ) : // end the quiz when question number exceeds 10
                    currentQuestion >= quiz.length ? (
                <CustomCard>
                    <h2>Quiz Ended</h2>
                    <h3>Your Score: {score}</h3>
                    {score >= 8 ? <h4>Wow! You know too much about Yan Naing 🥳</h4> :
                        score >= 5 ? <h4>Not bad! You know a fair share about Yan Naing 🎉</h4> :
                    <h4>DM him right now!! You gotta to know him better 🤔</h4>}
                    <QuizButton onClick={resetQuiz} message="Restart"/>
                </CustomCard>
            ) : ( // display the quiz
                <CustomCard>
                    <h2>Question {currentQuestion+1}</h2>
                    <Quiz quiz={quiz[currentQuestion]} onAnswerClick={handleAnswer}/>
                    <QuizButton onClick={resetQuiz} message="Quit"/>
                </CustomCard>

            )}
        </div>
    )
}

export default Trivia;