import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import DesktopNavBar from "./components/NavBars/DesktopNavBar/DesktopNavBar";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Projects from "./pages/Projects/Projects";
import University from "./pages/University/University";
import BlogHome from "./pages/Blog/BlogHome";
import MediQuest from "./pages/Projects/MediQuest";
import FPLAssist from "./pages/Projects/FPLAssist";
import RomanizedMM from "./pages/Projects/RomanizedMM";
import Experience from "./pages/Experience/Experience";
import {useEffect, useState} from "react";
import FloatingNavBar from "./components/NavBars/MobileNavBar/FloatingNavBar";
import Trivia from "./pages/Trivia/Trivia";
import {BlogPage} from "./pages/Blog/BlogPage";

function App() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth);
        });
    });

    return (
        <Router>
            <div className="App">
                {windowWidth <= 768 ? <FloatingNavBar/> : <DesktopNavBar/>}
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/about" element={<About/>} />
                    <Route path="/projects" element={<Projects/>} />
                    <Route path="/projects/mediquest" element={<MediQuest/>} />
                    <Route path="/projects/fplassist" element={<FPLAssist/>} />
                    <Route path="/projects/romanizedmm" element={<RomanizedMM/>} />
                    <Route path="/experience" element={<Experience/>} />
                    <Route path="/university" element={<University/>} />
                    <Route path="/trivia" element={<Trivia/>} />
                    <Route path="/blog" element={<BlogHome/>} />
                    <Route path="/blog/:id" element={<BlogPage />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;