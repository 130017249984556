import {Card} from "@mui/material";
import {styled} from "@mui/system";

export const CustomCard = styled(Card)({
    margin: '0rem 1rem',
    width: '65vw',
    height: 'auto',
    selfAlign: 'center',
    display: 'flex',
    background: 'transparent',
    boxShadow: 'none',
    color: 'white',
    flexDirection: 'column',
    '@media (max-width: 768px)': {
        width: '85vw',
    }
});