import React from "react";
import FPLImage from "../../images/HomePanel.jpg";
import {CustomCard} from "../CustomCard";
import {List} from "@mui/material";
import ContactBar from "../../components/ContactBar/ContactBar";

function FPLAssist() {
    return (
        <div className="project-page">
            <h1>FPL Assist</h1>
            <img src={FPLImage} alt="FPL Assist"></img>
            <h3><a href="https://github.com/ynwin1/Fantasy-Assist">GitHub</a></h3>
            <h3><u>Tech stack</u>: Java, Swing</h3>
            <CustomCard>
                <p>
                    I created this app while I was taking a software construction course at UBC. When I started playing
                    Fantasy Premier League (FPL), there weren't many helper apps that would provide users with sufficient data when it
                    comes to decision making about player picking. It had long gone been my dream to create an app with this idea and I got
                    this opportunity to implement it at UBC. However, after planning out, I realized how not easy this project was going to be,
                    and with the duration given to complete the project, I decided to go with the FPL team management interface with a dashboard
                    to view player statistics.
                </p>
                <p>
                    It was a great opportunity to apply the knowledge from the course. I understood the concept of OOP well enough
                    after this, and also learnt how important it is to write unit tests for every feature I implemented. This project
                    played a crucial role in securing my co-op at MacroHealth, because I got asked a lot about it haha.
                </p>
                <h3><u>Features</u></h3>
                <List>
                    <li>Create Team</li>
                    <li>Budget system where 15 players must be owned by using only $100</li>
                    <li>View player & team stats</li>
                    <li>Transfer players</li>
                    <li>User guide</li>
                    <li>Save/Load mechanism using JSON</li>
                </List>
                <h3><u>Challenges</u></h3>
                <p>
                    Since before I worked on this, I had in mind that handling the data was going to be a huge task I have to
                    overcome, considering the sheer amount of players available in each team. In the actual Fantasy Premier
                    League app, there are 20 teams with each team having around 25 players. This led me to reduce the number
                    of teams to 7. My knowledge about data handling was pretty limited, thus I decided to input all those
                    data within the app. This meant that I have to initialise all these data whenever an app is started.
                    There were no big issues about this apart from the fact that my start-up program is very long.
                    Testing and debugging throughoutly by writing JUnit tests was a fun challenge too, and it definitely
                    helped strengthen the code.
                </p>
            </CustomCard>
            <ContactBar/>
        </div>
    )
}

export default FPLAssist;