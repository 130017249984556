import React from "react";
import {List} from "@mui/material";
import {styled} from "@mui/system";

export const SkillCard = (props) =>{
    return (
        <div className="skill-card">
            <h4>{props.title}</h4>
            <List>
                {props.skills.map((skill, index) => (
                    <li key={index}>{skill}</li>
                ))}
            </List>
        </div>
    )
};

export const SkillSection = styled('div') ({
    margin: '1rem 1rem',
    width: '65vw',
    height: 'auto',
    selfAlign: 'center',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    background: 'transparent',
    boxShadow: 'none',
    color: 'white',
    flexDirection: 'row',
    '@media (max-width: 768px)': {
        width: '85vw',
        gridTemplateColumns: '1fr'
    }
});