import React, {useEffect, useState} from "react";
import {BlogCard, BlogPageHeader, BlogTag, BlogTagsContainer} from "./BlogComponents";
import {useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";

export function BlogPage() {
    const {id} = useParams();
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        import(`../../blogs/blog${id}.json`)
            .then(data => setBlog(data))
            .catch(err => console.error(err));
    }, [id]);

    if (!blog) {
        return <div className="blog-loading">
            <CircularProgress />
        </div>;
    }

    let imgCounter = 0;

    return (
        <div className="blog-page">
            <BlogPageHeader>
                <div className="blog-header-textbox">
                    <h1>{blog.title}</h1>
                    <h4>{blog.subtitle}</h4>
                    <BlogTagsContainer>
                        {blog.tags.map((tag) => (
                            <BlogTag>{tag}</BlogTag>
                        ))}
                    </BlogTagsContainer>
                </div>
                <img className="blog-header-img" src={blog.image} alt="Blog Image"/>
            </BlogPageHeader>
            <BlogCard>
                <p><b>Written on - {blog.date}</b></p>
                {blog.content.split('\n').map((line, index) => (
                    <>
                        <p>{line}</p>
                        {blog.inBlogImgs && index % 2 == 1 && imgCounter < blog.inBlogImgs.length && (
                            <img className="blog-img" src={blog.inBlogImgs[imgCounter++]} alt="Blog Image"/>
                        )}
                    </>
                ))}
            </BlogCard>
        </div>
    );
}