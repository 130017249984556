import React, {useState} from "react";
import {Button} from "@mui/material";
import {styled} from "@mui/system";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {Link} from "react-router-dom";
import '../../../App.css';

const MenuButton = styled(Button)({
    background: 'white',
    border: '3px solid black',
    borderRadius: '5rem',
    width: '3rem',
    height: '2rem',
});

function FloatingNavBar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const pages = [
        { route: "/", name: "Home" },
        { route: "/about", name: "About" },
        { route: "/experience", name: "Experience" },
        { route: "/projects", name: "Projects" },
        { route: "/university", name: "University" },
        { route: "/trivia", name: "Trivia" },
        { route: "/blog", name: "Blog" }
    ]

    const Menu = () => {
        return (
            <div className="menu">
                <CloseRoundedIcon className="nav-close-button" style={{width: '2.5rem', height: 'auto', marginBottom: '1rem'}} onClick={() => setIsMenuOpen(false)}/>
                {pages.map((page, index) => (
                    <Link key={index} to={page.route} style={{marginBottom: '1rem'}}
                          onClick={() => setIsMenuOpen(false)}>
                        {page.name}
                    </Link>
                ))}
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem', marginRight: '1.5rem', zIndex: 1200 }}>
            <MenuButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <MenuRoundedIcon />
            </MenuButton>
            {isMenuOpen && <Menu />}
        </div>
    );
}

export default FloatingNavBar;