import {styled} from "@mui/system";
import {AppBar, Button, Toolbar} from "@mui/material";

/*
    * Custom styling for the DesktopNavBar component
    * @type {StyledComponent}
    * Transparent background, no shawdow
 */
export const  DesktopNavBarStyled = styled(AppBar)({
    background: 'transparent',
    height: '5rem',
    justifyContent: 'center',
    position: 'static',
    color: 'white',
    boxShadow: 'none'
});

/*
    * Custom styling for the DesktopNavButton component
    * @type {StyledComponent}
    * dark blue background, and hover effect
*/
export const DesktopNavButtonStyled = styled(Button)({
    padding: '10px 20px',
    fontSize: '1rem',
    textTransform: 'none',
    color: '#FFFFFF',
    '&:hover': {
        color: '#000000',
        background: '#FFFFFF'
    },
});

/* Custom styling for the DesktopToolBar component
    * @type {StyledComponent}
    * Space between items and flex display
*/
export const DesktopToolBarStyled = styled(Toolbar)({
    justifyContent: "flex-end",
    display: 'flex',
    flexDirection: 'row',
})

